@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');


body {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
  }
  
  /* Header Styles */
  nav {
    background-color: #000000;
    color: white;
    padding: 0rem 2rem;
    position: fixed;
    width: 100%;
    z-index: 10;
    box-shadow: 0px 2px 5px rgb(0, 0, 0);
    text-transform: uppercase;
    font-weight: 900;
    font-size: 9.6px;
    }
    
    nav::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 10px;
      width: 100%;
      height: .5px; /* Thickness of the underline */
      background: rgb(95, 94, 94) /* Color of the underline */
    }

  
  nav ul {
    display: flex;
    justify-content:center;
    align-items: center;
    gap: 2rem;
    list-style: none;
    padding: 0;
    margin: 0;
    width:68%;
  }

  .logo
  {
    margin:0 100px 0 100px;
    animation: expandLogo 0.08s ease-out forwards;

  }

  
  
  nav ul li a {
    color: rgb(255, 255, 255);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  nav ul li a:hover {
    color: rgb(102, 88, 10);
  }
  @media screen and (min-width: 769px) { /* Shrink effect only applies on tablets & larger screens */
  .Header-nav.shrink .logo {
    animation: shrinkLogo 0.08s ease-out forwards;
  }

  .Header-nav.shrink {
    padding: 0.8rem 2rem; /* Reduce padding to shrink height */
    background-color: rgb(0, 0, 0); 
    animation: shrinkBackground 0.08s ease-out forwards;
  }

  @keyframes shrinkBackground {
    from {
        height: 100px;
    }
    to {
        height: 50px;
    }
}

  @keyframes shrinkLogo {
    from {
      width: 200px
    }
    to {
      width: 100px
  }
}
@keyframes expandBackground {
  from {
      height: 50px;
  }
  to {
      height: 100px;
  }
}

  @keyframes expandLogo {
    from {
      width: 100px
    }
    to {
      width: 200px;
    }
  }
}

  /* Responsive Design */
@media screen and (max-width: 1024px) {
  /* Tablet Styles */
  nav {
      padding: 0.5rem 1.5rem;
      font-size: 12px;
  }

  nav ul {
      gap: 0.2rem;
      width: 80%;
  }

  .logo {
      margin: 0 50px;
  }
}

@media screen and (max-width: 768px) {
  /* Mobile Styles */
  nav {
      padding: 0.5rem;
      font-size: 11px;
  }

  .logo {
      margin: 0 auto;
  }
}

.menu-toggle {
  display: none;
  font-size: 36px;
  cursor: pointer;
  color: white;
  top: 15px;
  left:50%;
  text-align: center;
  z-index: 20;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .nav-ul {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    background-color: black;
    text-align: center;
    flex-direction: column;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transform: translateY(-110%);
    opacity: 0;
    display: flex;
  }

  .nav-ul.open {
    transform: translateY(0);
    opacity: 1;
  }

  .nav-ul li {
    padding: 0px 0px 30px 0px;
  }
}

  /* Section Styles */
  
  
  .section h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .bg-gray-100 {
    background-color: #F3F4F6;
    box-shadow: 10px 10px 20px black;
  }
  
  .bg-white {
    background-color: white;
    box-shadow: 10px 10px 20px black;
  }

  .bg-gray-100,
  .bg-white {
  transition: box-shadow 0.1s ease-in, transform 0.1s ease-in-out;
  width:100%;
  }

  .bg-gray-101 {
    padding-top: 15rem;
    padding-bottom: 15rem;
    color: #ffffff; /* Dark gray text color */
    text-shadow: 1px 2px black;
  }
  .bg-gray-101 p {
    text-shadow: 2px 1px black;
  }
  .bg-gray-101 button {
    background-color:rgba(0, 0, 0, 0.432);
    color:white;
    font-family: Roboto;
    text-transform: uppercase;
    font-weight: bold;
    font-size: .6rem;
    border: 3px solid white;
    border-color: rgb(102, 88, 10);
    border-radius: 2px;
    width:100px;
    height:32px;
    margin:10px 20px 10px 0px;
    transition: color 0.3s ease, border-color 0.3s ease, background-color 0.3s ease;
  }
  .bg-gray-101 button:hover{
    color:rgba(255, 255, 255, 0.788);
    border-color: rgba(255, 255, 255, 0.788);
    background-color: rgba(0, 0, 0, 0.6);
  }

    .background-video {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100vh;
      min-height: 100vh;
      width:101%;
      height:100%;
      object-fit: cover;
      background-attachment: fixed;
      z-index: -1;

  }
  
  
 .home-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 65%;
  margin: auto;
  
 }
 .other-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 65%;
  margin: auto;
  padding: 5rem 0;
 }

 .client-other-section{
  padding: 5rem 1rem;
 }

 .bg-gray-100:hover,
 .bg-white:hover {
  box-shadow: 15px 15px 30px rgb(102, 88, 10);
  transform: scale(1.02);
 }

 .text-content {
  flex: 1;
  text-align: left;
  max-width: 40%;
 }
 
 .client-text-content{
  text-align: center;
 }

 .client-text-content h1{
  margin:0;
  font-size:1.8rem;
 }

 .client-text-content p{
  margin-bottom:50px;
 }

 .section-image{
  width:40%;
  max-width:1200px;
  border-radius: 20px 20px 20px 20px;
  transition: box-shadow 0.3s ease-in;
 }
 .section-image:hover{
  box-shadow: 0px 10px 10px rgb(102, 88, 10);
 }
 .left{
  order: -1;
 }
 .right {
  order: 1;
  height:400px;
 }

 .bg-white button,
 .bg-gray-100 button {
  background-color:rgb(102, 88, 10);
  color:white;
  font-family: Roboto;
  text-transform: uppercase;
  font-weight: bold;
  font-size: .6rem;
  width:100px;
  height:32px;
  margin:10px 20px 10px 0px;
  transition: color 0.3s ease, background-color 0.3s ease;
}
.bg-gray-101 a,
.bg-white button a,
 .bg-gray-100 button a {
color: white;
text-decoration: none;
}


.bg-white button:hover,
.bg-gray-100 button:hover{
  background-color: rgba(0, 0, 0, 0.87);
}
.other-section h1 {
  font-size: 1.8rem;
}
/* Client Photos Homepage */

.slick-slide {
  transition: transform 0.3s ease;
}

.slick-prev, .slick-next {
  z-index: 1;
  color: black;
  font-size: 2rem;
}

.client-photo {
  width: 50%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}
.client-photo:hover{
  transform: scale(1.1);
}

.slick-dots {
  bottom: -40px; /* Adjust position of dots */
  display: flex;
  justify-content: center;
}

@media (max-width: 1024px) {
  .home-section, .other-section {
    width: 80%; /* Increase width for medium screens */
    flex-direction: column; /* Stack items vertically */
    text-align: center;
  }
  
  .text-content {
    max-width: 100%; /* Allow full width for text */
    margin-bottom: 2rem;
  }

  .client-text-content h1,
  .text-content h1 {
    font-size: 1.6rem;
    text-align: center;
  }
  .client-text-content p,
  .text-content p {
    font-size: 1rem;
    text-align: center;
  }

  .bg-gray-101 button,
  .bg-gray-100 button,
  .bg-white button {
    width: 100%; /* Increase button size */
    height: 40px;
    font-size: 0.8rem;
  }

  .section-image {
    width: 60%; /* Make images fit better */
  }

  .right {
    height: auto; /* Allow flexible height */
  }
}

@media (max-width: 768px) {
  .home-section, .other-section {
    display: flex;
  flex-direction: column;
  align-items: center; /* Ensures content is centered */
  justify-content: center;
  text-align: center;
  width: 100%;
  }

  .section h1 {
    font-size: 2rem; /* Reduce heading size */
  }

  .bg-gray-101 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .bg-gray-101 button,
  .bg-gray-100 button,
  .bg-white button {
    width: 100%; /* Increase button size */
    height: 40px;
    font-size: 0.8rem;
  }
  .client-text-content h1,
  .text-content h1 {
    font-size: 1.6rem;
    text-align: center;
  }
  .client-text-content p,
  .text-content p {
    font-size: 1rem;
    text-align: center;
  }

  .section-image {
    width: 75%;
  }

  .background-video {
    width: 100vw;
    min-width: auto;
    min-height: auto;
  }
}

@media (max-width: 480px) {
  .home-section, .other-section {
    width: 85%;
    flex-direction: column;
    padding: 2rem 1rem;
    align-items: center;
  }
  .client-text-content h1,
  .text-content h1 {
    font-size: 1.6rem;
    text-align: center;
  }
  .client-text-content p,
  .text-content p {
    font-size: 1rem;
    text-align: center;
  }

  .client-photo{
    width:60%;
  }

  .section-image {
    width: 100%;
    border-radius: 10px;
  }

  .bg-gray-101 button,
  .bg-gray-100 button,
  .bg-white button {
    width: 100%;
    height: 45px;
    font-size: 1rem;
  }
}



/* Preloader Styles */
.fs-preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: opacity 1s ease-out; /* Smooth fade-out transition */
}

.fs-preloader-logo img {
  width: 200px; /* Adjust as needed */
  animation: fs-fade-scale 1.5s ease-in-out infinite alternate;
}

@keyframes fs-fade-scale {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1.2);
  }
}

/* Fade-out effect for Loading Screen */
.fs-preloader-fadeout {
  opacity: 0;
  pointer-events: none; /* Prevent interaction when fading out */
}
 
.fs-loading-dots {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 10px;
}

.fs-loading-dots span {
  width: 6px;
  height: 6px;
  background: white;
  border-radius: 50%;
  animation: fs-dots 1.5s infinite ease-in-out;
}

.fs-loading-dots span:nth-child(1) {
  animation-delay: 0s;
}
.fs-loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}
.fs-loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes fs-dots {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
}

/* Footer Styles */
.footer {
  padding: 3rem 0;
  text-align: center;
  background-color: #000000;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1100px;
  margin: auto;
  padding: 0 2rem;
}


.footer-brand,
.footer-links,
.footer-social {
  flex: 1;
  min-width: 250px;
  margin-bottom: 1rem;
}

.footer h2,
.footer h3 {
  margin-bottom: 1rem;
  color:rgb(255, 255, 255)
}

.footer p {
  font-weight: bold;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color:rgb(255, 255, 255)
}

.footer-links ul {
  font-weight: bold;
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin: 0.5rem 0;
}

.footer-links a {
  color: rgb(255, 255, 255);
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: rgb(102, 88, 10);
}

.footer-social .social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.footer-social a {
  color: white;
  font-size: 1.5rem;
  transition: color 0.3s;
}

.footer-social a:hover {
  color: gray;
}

.footer-bottom {
  margin-top: 2rem;
  font-size: 0.8rem;
  border-top: 1px solid gray;
  padding-top: 1rem;
}
.footer-logo-container {
  text-align: center;
  margin-bottom: 2rem; /* Adjust spacing as needed */
}

.footer-logo {
  display: inline-block;
  max-width: 150px; /* Adjust the size as needed */
  width: 100%;
  height: auto;
}

/* Services Page */
.menu-page {
  font-family: 'Arial', sans-serif;
}

.services-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.services-category-container {
  background: #000000a1;
  justify-items: center;
  
}
.category-section-container {
  display:grid;
  grid-template-columns: repeat(3, 0.5fr);
  text-align: center;
  gap:2rem;
  padding-top: 50px;
}

.category-section{
border-bottom: 2px solid rgb(102, 88, 10);
margin: 0px 20px 0px 20px;
margin-bottom: 20px;
}

.category-section h1 {
  font-size: 30px;
  color: rgb(102, 88, 10);
  text-shadow: 0px 2px 2px black;
  
}

.service-card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  margin-bottom: 40px;
  padding-bottom: 30px;
  border: 4px solid rgb(102, 88, 10);
  border-radius: 10px;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgb(102, 88, 10);
}

.service-name {
  font-size: .9rem;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
  
}

.service-card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 1024px) {
  .category-section-container {
    grid-template-columns: repeat(2, 1fr); /* Adjust to two columns for tablets */
    gap: 1.5rem;
    padding-top: 30px;
  }
}

@media (max-width: 768px) {
  .category-section-container {
    grid-template-columns: 1fr; /* Stack categories in one column for mobile */
    gap: 1rem;
    padding-top: 20px;
  }

  .category-section h1 {
    font-size: 24px; /* Adjust font size */
  }

  .services-list {
    grid-template-columns: 1fr; /* Services stack vertically */
  }

  .service-card {
    padding: 15px;
    font-size: 0.8rem;
  }

  .text-content h1 {
    font-size: 28px; /* Reduce heading size */
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .buttons button {
    width: 100%;
  }
}

/* Appointment Section */


.book-appointment-section h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: rgb(102, 88, 10);
  text-align: center;
  text-shadow: 2px 2px black;
}

.book-appointment-section p {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #ffffff;
  text-shadow: 2px 1px black;
}

/* Appointment Form Container */
.appointment-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 25%;
  margin: auto;
  background-color: #ffffff;
  padding: 2rem;
  box-shadow: 0 4px 8px rgb(0, 0, 0);
  border-radius: 8px;
  border: 5px solid rgb(102, 88, 10);
}

.appointment-form .form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.appointment-form label {
  font-size: 1rem;
  color: #333;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

option {
  padding: 0.8rem;
  font-size: 1rem;
  background-color: #fff;
  color: #333;
}

.appointment-form input,
.appointment-form select {
  padding: 1rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border 0.3s ease-in-out;
  background-color: #f8f8f8;
  width:100%;
}

.appointment-form input:focus,
.appointment-form select:focus {
  border-color: rgb(102, 88, 10);
  outline: none;
}

.appointment-form button {
  background-color: rgb(102, 88, 10);
  color: white;
  font-family: Roboto, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  padding: 1rem;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.appointment-form button:hover {
  background-color: rgba(0, 0, 0, 0.87);
}

/* Additional Form Styling */
.appointment-form input,
.appointment-form select {
  padding: 0.8rem;
}

.book-appointment-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.664);
  padding: 3rem 0 3rem 0;
}

/* About us page */

.about-text-content {
  display:flex;
  color:rgb(255, 255, 255);
  text-shadow: 0px 2px 2px rgb(0, 0, 0);
  font-size: 1.2rem;
  font-weight:bold;
  background-color:black;
  padding:0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.664);
  justify-content: center;
  perspective: 400px; /* Add perspective to the animation */
  font-family: "Lora", serif;
}
.about-text-content p{
  margin: 0;
  padding-top: 50px;
}
.about-text {
  max-width:40%;
}
.about-text img{
  width:50%;
  height:60%;
  transition: box-shadow 0.3s ease-in, transform 0.3s ease-in;
  grid-template-columns: repeat(3, 0.5fr);
  margin:20px;
}
.about-text img:hover{
  box-shadow: 0px 10px 20px rgb(102, 88, 10);
  transform: scale(1.1);
}

@media (max-width: 1024px) {
  .about-text{
    font-size: 16px;
    max-width:60%;
  }
  }

@media (max-width: 768px) {
.about-text{
  font-size: 14px;
  max-width:60%;
}
}

@media (max-width: 480px) {
.about-text {
  font-size:12px;
  max-width:60%;
}
}